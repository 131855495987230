<template>
<div>
    <Header />

     <div class="banner bg-dark text-white">
            <div class="container">
                <div class="row">
                    <div class="col py-5">
                        <h1><b>About us</b></h1>
                    </div>
                </div>
            </div>
        </div>

        <div class="py-5 bg-gradient pb-10 arrow-bottom">
            <div class="container">
                <div class="row pb-5">
                    <div class="col-6 col-md-3 offset-md-2 text-center">
                        <img src="@/assets/img/rebeca-lan.jpg" alt="Rebeca Lan" class="rounded-circle img-fluid mb-3" height="150">
                        <br>
                        <p class="text-center"><b>Rebeca Lan</b><br>
                        (1946 – 2005) </p>
                    </div>
                    <div class="col-6 col-md-3 offset-md-2 text-center">
                        <img src="@/assets/img/rashel-cohen-lan.jpg" alt="Rashel Stephanie Cohen Lan" class="rounded-circle img-fluid mb-3" height="150">
                        <br>
                        <p class="text-center"><b>Rashel Stephanie Cohen Lan</b><br>
                        Founder and President</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                       <p>Rashel Cohen Lan was only 13 years old when she was separated from her mother, Mrs. Rebeca Lan, for eight years through Mrs. Rebeca Lan's imprisonment.</p>
                       <p>Rashel witnessed a world of loneliness, abuse and lack of dignity inside the prison. The hardest loss for both mother and daughter was the separation during crucial years of Rashel's childhood and the feeling of maternal loss.</p>
                       <p>Rebeca Lan was a loving and brave woman. After overcoming her pain, she became a ray of light for her fellow inmates. During Rebeca's time in the system, she managed to turn her time of confinement into a ladder of growth for redemption. She was able to finally gain the strength to believe in herself and inspire others. She empowered women to gain the strength to fight for a dignified life of freedom which they deserved.</p>
                       <p>In 2016, Rashel created the Rebeca Lan Foundation in order to continue the noble work of her mother's legacy which involved women's transformation. The love, courage and strength that Rashel learned from her mother gave her the tools to foster this organization. The organization brings women closer to the possibility of changing their paths and entering back into society while gaining emotional healing, creating family bonding, and learning advanced life skills which leads them back into the labor force.</p>
                       <p>Throughout the years since the inception and with intense determination, devotion and perseverance, Rashel has built her organization which has brought so much light into these affected women and their families. Through this effort, The Rebeca Lan Foundation has gained hundreds of volunteers who truly believe in the power and mission of the organization.</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="pt-10 pb-5 bg-gradient">
            <div class="container">
                <div class="py-5">
                    <div id="mision" class="card-deck mb-5">

                        <div class="card shadow border-0">
                            <div class="card-body">
                                <h2 class="font-weight-bold text-secret h5">Mission</h2>
                                <p>Contribute to the social reintegration of women deprived of liberty. Positively impact their children and family members through the <b>Rebeca Lan Model</b>, which is aimed at developing competencies and skills of women in their personal, family and work life. The organization facilitates their reintegration into society while reducing the chances of relapse.</p>
                            </div>
                        </div>

                        <div class="card shadow border-0">
                            <div class="card-body">
                                <h2 class="font-weight-bold text-secret h5">Vision</h2>
                                <p>Replicate the Rebeca Lan model in all women's penitentiary centers in Mexico during their time in prison and post prison, impacting a larger audience of women deprived of their liberty and their children.</p>
                            </div>
                        </div>

                    </div>
                    <div id="valores" class="row mb-3">
                        <div class="col">
                            <h2 class="font-weight-bold text-secret h5">Our Values</h2>
                        </div>
                    </div>
                    <div class="card-deck">

                        <div class="card shadow border-0 mb-4">
                            <div class="card-body">
                                <p><b>Trust and Confidence</b></p>
                                <p>We foster a bond of trust between the community of <b>women in prison</b>, their families and authorities.</p>
                            </div>
                        </div>

                        <div class="card shadow border-0 mb-4">
                            <div class="card-body">
                                <p><b>Perseverance</b></p>
                                <p>For the <b>untiring work</b> that increases the women benefiting through our impact programs.</p>
                            </div>
                        </div>

                        <div class="card shadow border-0 mb-4">
                            <div class="card-body">
                                <p><b>Respect</b></p>
                                <p>We respect <b>women</b>, their families and the authorities. We continually advocate for them throughout the process.</p>
                            </div>
                        </div>

                    </div>
                    <div class="card-deck mb-5">

                        <div class="card shadow border-0 mb-4">
                            <div class="card-body">
                                <p><b>Honesty</b></p>
                                <p>We consistently <b>honor our commitments</b>, having transparency in all of our actions.</p>
                            </div>
                        </div>

                        <div class="card shadow border-0 mb-4">
                            <div class="card-body">
                                <p><b>Love</b></p>
                                <p>Love is the engine that drives each and every one of our actions.</p>
                            </div>
                        </div>

                        <!-- <div class="card shadow border-0 mb-4">
                            <div class="card-body">
                                <p><b>Modesty</b></p>
                                <p>Because with the humility of recognizing that we need others, we are <b>continuously adding help</b> that brings us closer to our mission.</p>
                            </div>
                        </div> -->

                    </div>
                </div>

                <div id="premios" class="premios pt-5">
                    <div class="row">
                        <div class="col text-center">
                            <h2 class="pb-3 mb-4"><b>Awards</b></h2>
                        </div>
                    </div>
                    <div class="row row-cols-1 row-cols-md-4">
                        <div class="col">
                            <img src="@/assets/img/premios/premios-marcos-katz-halpern.jpg" alt="Agentes de Cambio (Agents of Change) Marcos Katz Halpern 2018 Award Social" class="rounded img-fluid mb-3">
                            <p><b>Agentes de Cambio (Agents of Change) Marcos Katz Halpern 2018 Award Social</b></p>
                            <p>Entrepreneurship Award -Third place- for being a Civil Society Organization focused on improving the quality of life of vulnerable communities in the country, solving social problems through innovative approaches.</p>
                        </div>
                        <div class="col">
                            <img src="@/assets/img/premios/premios-derechos-humanos.jpg" alt="Regional Human Rights Award 2019" class="rounded img-fluid mb-3">
                            <p><b>Regional Human Rights Award 2019</b></p>
                            <p>Award from Federación Mexicana de Organismos Públicos of the National Human Rights Commission for the outstanding work in the promotion and defense of human rights.</p>
                        </div>
                        <div class="col">
                            <img src="@/assets/img/premios/premios-accion-voluntaria.jpg" alt="National Award for Volunteering and Solidarity 2019" class="rounded img-fluid mb-3">
                            <p><b>National Award for Volunteering and Solidarity 2019</b></p>
                            <p>The Mexican Government awarded Rashel Cohen Lan, in the “Individual Category” for her volunteer and humanitarian work for the benefit of the community, specifically for women deprived of liberty, their families and children.</p>
                        </div>
                        <div class="col">
                            <img src="@/assets/img/premios/premios-mujer-de-valor.jpg" alt="Woman of Worth 2019 by L'Oreal Paris" class="rounded img-fluid mb-3">
                            <p><b>Woman of Worth 2019 by L'Oreal Paris</b></p>
                            <p>L'Oreal Paris recognized Rashel Cohen Lan as a Woman of Worth for the work she does for women deprived of liberty and their children</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <Footer />
</div>
</template>

<style lang="less">
    @import "@/assets/css/colors.less";
    @import "@/assets/css/main.less";
    @import "./css/nosotros.less";
    .bg-dark{
        overflow: hidden;
        position: relative;
        background: rgb(0,0,0);
        background: linear-gradient(90deg, rgba(0,0,0,1) 50%, rgba(0,0,0,0) 100%);
        text-shadow: 0 1px 15px rgba(0,0,0,.8);

        &::before {
            background-image: url('../../../assets/img/bg-image.jpg');
            content: "";
            background-size: cover;
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            opacity: 0.35;
        }
    }
</style>

<script>
import Header from '@/modules/en/Templates/Header'
import Footer from '@/modules/en/Templates/Footer'

export default {
    name: "Nosotros",
    components:{
        Header,
        Footer
    },
    mounted(){
       if(window.location.hash) {
            const id = window.location.hash.substring(1);
            const yourElement = document.getElementById(id);
            const y = yourElement.getBoundingClientRect().top + window.pageYOffset;

            window.scrollTo({top: y, behavior: 'smooth'});
        }
    }
}
</script>

